<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title> Importer un ticket Z </template>

    <template #help>
      Déposez le ticket Z mensuel de votre caisse enregistreuse (au format pdf, png ou jpg) et
      indiquer les montants HT correspondant à chaque taux de TVA et à chaque type de ventes
      (marchandises, prestations de services, production)
    </template>

    <template #content>
      <div class="uds-form-container">
        <div v-if="step > 1" class="modal-header">
          {{ MonthList[form.month - 1].text }} {{ form.year }}
        </div>
        <template v-if="step === 1">
          <CommonsDropzoneContainer
            :extensions="basicImageAndPdfMimeType"
            :max-files="1"
            :max-size="1.9"
            :disabled="isLoading"
            :show-drop-box-button="true"
            @updated="files = $event"
          />
          <div class="uds-form-row calendar-row">
            <uds-input-select-auto-popper
              label="Mois du dépôt"
              :value="form.month"
              :items="MonthList"
              @select="form.month = $event || null"
            />
            <uds-input-select-auto-popper
              label="Année du dépôt"
              :value="form.year"
              :items="yearsList"
              @select="form.year = $event || null"
            />
          </div>
        </template>
        <template v-if="step === 2">
          <div v-for="(sale, i) of sales" :key="i" class="sale-container">
            <div class="sale-header">
              <uds-input-select-auto-popper
                label="Type de vente"
                :value="sale.saleType"
                :items="possibleSaleTypeList(sale.saleType)"
                style="flex: 1"
                @select="sale.saleType = $event || null"
              />
              <uds-icon v-if="i !== 0" icon-name="delete" class="icon" @click="removeSale(i)" />
            </div>
            <div class="sale-content">
              <div class="sale-block">
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT 20%"
                    hide-icon
                    :min="0"
                    :value="sale.TX_20"
                    @input-spe="(sale.TX_20 = $event), calculateTvaTX(20, $event, i)"
                  />
                  <uds-input-amount
                    label="Montant TVA 20%"
                    hide-icon
                    :min="0"
                    :value="sale.vat_amount_TX_20"
                    @input-spe="sale.vat_amount_TX_20 = $event"
                  />
                </div>
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT 8,5%"
                    hide-icon
                    :min="0"
                    :value="sale.TX_8_5"
                    @input-spe="(sale.TX_8_5 = $event), calculateTvaTX(8.5, $event, i)"
                  />
                  <uds-input-amount
                    label="Montant TVA 8,5%"
                    hide-icon
                    :min="0"
                    :value="sale.vat_amount_TX_8_5"
                    @input-spe="sale.vat_amount_TX_8_5 = $event"
                  />
                </div>
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT 2,1%"
                    hide-icon
                    :min="0"
                    :value="sale.TX_2_1"
                    @input-spe="(sale.TX_2_1 = $event), calculateTvaTX(2.1, $event, i)"
                  />
                  <uds-input-amount
                    label="Montant TVA 2,1%"
                    hide-icon
                    :min="0"
                    :value="sale.vat_amount_TX_2_1"
                    @input-spe="sale.vat_amount_TX_2_1 = $event"
                  />
                </div>
              </div>
              <div class="sale-block">
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT 10%"
                    hide-icon
                    :min="0"
                    :value="sale.TX_10"
                    @input-spe="(sale.TX_10 = $event), calculateTvaTX(10, $event, i)"
                  />
                  <uds-input-amount
                    label="Montant TVA 10%"
                    hide-icon
                    :min="0"
                    :value="sale.vat_amount_TX_10"
                    @input-spe="sale.vat_amount_TX_10 = $event"
                  />
                </div>
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT 5,5%"
                    hide-icon
                    :min="0"
                    :value="sale.TX_5_5"
                    @input-spe="(sale.TX_5_5 = $event), calculateTvaTX(5.5, $event, i)"
                  />
                  <uds-input-amount
                    label="Montant TVA 5,5%"
                    hide-icon
                    :min="0"
                    :value="sale.vat_amount_TX_5_5"
                    @input-spe="sale.vat_amount_TX_5_5 = $event"
                  />
                </div>
                <div class="sale-block-line">
                  <uds-input-amount
                    label="Montant HT sans TVA"
                    hide-icon
                    :min="0"
                    :value="sale.WITHOUT_TAXE"
                    style="flex: 1"
                    @input-spe="sale.WITHOUT_TAXE = $event"
                  />
                </div>
              </div>
            </div>
          </div>
          <uds-main-button icon-name="add" type="link" class="sale-adder" @click="addSale()">
            Ajouter un type de vente</uds-main-button
          >
        </template>
        <template v-if="step === 3">
          <div class="uds-form-row">
            <uds-input-amount
              label="Montant TTC espèces"
              hide-icon
              :min="0"
              :value="payments.CCASH"
              @input-spe="payments.CCASH = $event"
            />
            <uds-input-amount
              label="Montant TTC chèques"
              hide-icon
              :min="0"
              :value="payments.CCHECK"
              @input-spe="payments.CCHECK = $event"
            />
          </div>
          <div class="uds-form-row">
            <uds-input-amount
              label="Montant TTC tickets restaurants"
              hide-icon
              :min="0"
              :value="payments.CTICKET"
              @input-spe="payments.CTICKET = $event"
            />
            <uds-input-amount
              label="Montant TTC carte bancaire"
              hide-icon
              :min="0"
              :value="payments.CCARD"
              @input-spe="payments.CCARD = $event"
            />
          </div>
          <div class="uds-form-row">
            <uds-input-amount
              label="Montant TTC ANCV"
              hide-icon
              :min="0"
              :value="payments.CVOUCHER"
              @input-spe="payments.CVOUCHER = $event"
            />
            <uds-input-amount
              label="Montant TTC virements"
              hide-icon
              :min="0"
              :value="payments.CTRANSFER"
              @input-spe="payments.CTRANSFER = $event"
            />
          </div>
          <div class="uds-form-row">
            <uds-input-amount
              label="Montant TTC crédit client"
              hide-icon
              :min="0"
              :value="payments.CCREDIT"
              @input-spe="payments.CCREDIT = $event"
            />
            <div />
          </div>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="footer-container">
        <div v-if="step === 3 && isNotEquilibred" class="not-equilibred">
          Les montants par taux de TVA et par moyens de paiements ne correspondent pas
        </div>
        <div class="footer-actions">
          <uds-main-button
            type="tertiary"
            size="small"
            :disabled="isLoading"
            style="margin-right: 8px"
            @click="$emit('cancel')"
          >
            Annuler
          </uds-main-button>
          <uds-main-button
            v-if="step !== 1"
            size="small"
            :loading="isLoading"
            @click="previousStep"
          >
            Précédent
          </uds-main-button>
          <uds-main-button
            v-if="step < 3"
            size="small"
            :disabled="!isValid"
            :loading="isLoading"
            @click="nextStep"
          >
            Suivant
          </uds-main-button>
          <uds-main-button
            v-if="step === 3"
            size="small"
            :loading="isLoading"
            :disabled="!isValid"
            @click="send"
          >
            Terminer
          </uds-main-button>
        </div>
      </div>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { Sale, Payments } from "@silexpert/core";
import { SaleType } from "@silexpert/core";
import { basicImageAndPdfMimeType } from "~/utils/file-type";
import { MonthList } from "~/utils/const";

const societyStore = useSocietyStore();
const dayjs = useDayjs();
const { isComptalib } = useBrandsComposable();

const emit = defineEmits(["cancel", "close", "save"]);

const svg = isComptalib() ? "ComptalibManageFiscalPeriodSvg" : "ZTicketSvg";

const isLoading = ref(false);
const files = ref<File[]>([]);
const step = ref(1);
const isNotEquilibred = ref(false);

const form = ref({
  month: parseInt(dayjs().format("M")),
  year: dayjs().format("YYYY"),
});

const sales = ref<Sale[]>([
  {
    saleType: SaleType.PRODUCTION,
    TX_20: 0,
    TX_10: 0,
    TX_2_1: 0,
    TX_8_5: 0,
    TX_5_5: 0,
    WITHOUT_TAXE: 0,
    vat_amount_TX_20: 0,
    vat_amount_TX_10: 0,
    vat_amount_TX_2_1: 0,
    vat_amount_TX_8_5: 0,
    vat_amount_TX_5_5: 0,
  },
]);

const payments = ref<Payments>({
  CCASH: 0,
  CCHECK: 0,
  CCARD: 0,
  CTRANSFER: 0,
  CCREDIT: 0,
  CTICKET: 0,
  CVOUCHER: 0,
});

const saleTypeList: { value: number; text: string }[] = [
  {
    value: SaleType.PRODUCTION,
    text: "Production",
  },
  {
    value: SaleType.SERVICE,
    text: "Prestations de services",
  },
  {
    value: SaleType.MERCHANDISE,
    text: "Ventes de marchandises",
  },
];

const yearsList = [
  {
    value: dayjs().format("YYYY"),
    text: dayjs().format("YYYY"),
  },
  {
    value: dayjs().subtract(1, "year").format("YYYY"),
    text: dayjs().subtract(1, "year").format("YYYY"),
  },
  {
    value: dayjs().subtract(2, "year").format("YYYY"),
    text: dayjs().subtract(2, "year").format("YYYY"),
  },
  {
    value: dayjs().subtract(3, "year").format("YYYY"),
    text: dayjs().subtract(3, "year").format("YYYY"),
  },
];

const checkIfSalesAndPaymentsAreEquilibred = computed(() => {
  let salesTot = 0;
  sales.value.forEach((sale: Sale) => {
    [
      "TX_20",
      "TX_10",
      "TX_2_1",
      "TX_8_5",
      "TX_5_5",
      "WITHOUT_TAXE",
      "vat_amount_TX_20",
      "vat_amount_TX_10",
      "vat_amount_TX_2_1",
      "vat_amount_TX_8_5",
      "vat_amount_TX_5_5",
    ].forEach((tx) => {
      // @ts-expect-error
      if (typeof sale[tx] === "number") {
        // @ts-expect-error
        salesTot += sale[tx];
      }
    });
  });
  salesTot = Math.round((salesTot + Number.EPSILON) * 100) / 100;

  let paymentsTot = 0;
  ["CCASH", "CCHECK", "CCARD", "CTRANSFER", "CCREDIT", "CTICKET", "CVOUCHER"].forEach((type) => {
    // @ts-expect-error
    if (typeof payments.value[type] === "number") {
      // @ts-expect-error
      paymentsTot += payments.value[type];
    }
  });

  paymentsTot = Math.round((paymentsTot + Number.EPSILON) * 100) / 100;

  return salesTot === paymentsTot;
});

const isValid = computed<boolean>(() => {
  if (step.value === 1) {
    const isAFileDropped = files.value.length > 0;
    const isSelectedMonth = form.value.month !== null;
    const isSelectedYear = form.value.year !== null;

    return isAFileDropped && isSelectedMonth && isSelectedYear;
  } else if (step.value === 2) {
    let tot = 0;
    sales.value.forEach((sale) => {
      ["TX_20", "TX_10", "TX_2_1", "TX_8_5", "TX_5_5", "WITHOUT_TAXE"].forEach((tx) => {
        // @ts-expect-error
        if (typeof sale[tx] === "number") {
          // @ts-expect-error
          tot += sale[tx];
        }
      });
    });
    return tot > 0;
  } else if (step.value === 3) {
    let tot = 0;
    ["CCASH", "CCHECK", "CCARD", "CTRANSFER", "CCREDIT", "CTICKET", "CVOUCHER"].forEach((type) => {
      // @ts-expect-error
      if (typeof payments.value[type] === "number") {
        // @ts-expect-error
        tot += payments.value[type];
      }
    });
    if (tot > 0) {
      const equilibred = checkIfSalesAndPaymentsAreEquilibred.value;
      if (equilibred) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        isNotEquilibred.value = false;
        return true;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      isNotEquilibred.value = true;
    }
  }
  return false;
});

function nextStep() {
  step.value++;
}

function previousStep() {
  step.value--;
}

function addSale() {
  sales.value.push({
    saleType: possibleSaleTypeList()[0].value,
    TX_20: 0,
    TX_10: 0,
    TX_2_1: 0,
    TX_8_5: 0,
    TX_5_5: 0,
    WITHOUT_TAXE: 0,
    vat_amount_TX_20: 0,
    vat_amount_TX_10: 0,
    vat_amount_TX_2_1: 0,
    vat_amount_TX_8_5: 0,
    vat_amount_TX_5_5: 0,
  });
}

function removeSale(i: number) {
  sales.value.splice(i, 1);
}

function calculateTvaTX(tx: number, priceHt: number, index: number): void {
  const rateTx = tx / 100;
  let vatAmount = priceHt * rateTx;
  vatAmount = Math.round(vatAmount * 100) / 100;
  switch (tx) {
    case 20:
      sales.value[index].vat_amount_TX_20 = vatAmount;
      break;
    case 10:
      sales.value[index].vat_amount_TX_10 = vatAmount;
      break;
    case 2.1:
      sales.value[index].vat_amount_TX_2_1 = vatAmount;
      break;
    case 8.5:
      sales.value[index].vat_amount_TX_8_5 = vatAmount;
      break;
    case 5.5:
      sales.value[index].vat_amount_TX_5_5 = vatAmount;
      break;
  }
}

function possibleSaleTypeList(saleTypeValue?: number): { value: number; text: string }[] {
  let addedSaleType = null;
  if (saleTypeValue) {
    const res = saleTypeList.find((sale) => sale.value === saleTypeValue);
    if (res) {
      addedSaleType = res;
    }
  }
  return [
    ...saleTypeList.filter((saleType) => {
      let isNotFiltered = true;
      sales.value.forEach((sale) => {
        if (sale.saleType === saleType.value) {
          isNotFiltered = false;
        }
      });
      return isNotFiltered;
    }),
    ...(addedSaleType ? [addedSaleType] : []),
  ];
}
async function send() {
  isLoading.value = true;
  const idSociety: number = societyStore.society!.id!;

  const data = new FormData();
  data.append("sales", JSON.stringify(sales.value));
  data.append("payments", JSON.stringify(payments.value));
  data.append("file", files.value[0]);
  data.append("idSociety", idSociety.toString());
  data.append("dateValue", `${form.value.year}-${form.value.month}-01 10:00:00`);

  await $silex()
    .zTicket // @ts-expect-error
    .createZTicket(data)
    .then(() => {
      $notifier().open({ type: "success", content: "Ticket Z importé" });
      emit("save");
    })
    .catch((error: any) => $notifier().open({ type: "error", content: apiErrorToString(error) }));

  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.modal-header {
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.sale-container {
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-2;
  border-left: $uds-radius-1 solid $uds-google-blue;
  border-radius: 2px;
  padding-left: 10px;

  :deep(.uds-input-label) {
    font-size: 12px !important;
  }

  .sale-header {
    display: flex;
    align-items: center;
    gap: $uds-spacing-1;
    width: 100%;
    .icon {
      margin-top: $uds-spacing-2;
      height: fit-content;
      cursor: pointer;
    }
  }
  .sale-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $uds-spacing-1;
    .sale-block {
      padding-bottom: 10px;
      &:not(:first-child) {
        border-left: $uds-radius-1 solid $uds-google-blue;
        padding-left: $uds-spacing-1_5;
      }
      .sale-block-line {
        display: flex;
        gap: $uds-spacing-1;
        padding-bottom: $uds-spacing-1;
      }
    }
  }
}

.sale-adder {
  width: fit-content;
}

.footer-container {
  .not-equilibred {
    text-align: right;
    margin-bottom: 5px;
    color: red;
    font-size: 14px !important;
    font-family: "circular", sans-serif !important;
    font-weight: 100;
  }
}

.footer-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $uds-spacing-1;
}
</style>
